<template>
  <div>
    <center class="mt-5">
      <div class="mb-3">
        <b-img
          :src="require('@/assets/images/logo/logo1.jpg')"
        />
      </div>
      <div
        v-if="userObj.rowState===0"
      >
        <h1 class="text-danger">
          Doğrulama başarısız.
        </h1>
        <b-img
          :src="require('@/assets/images/icons/fail.png')"
        />
      </div>
      <div
        v-if="userObj.rowState===2"
      >
        <h1 class="text-primary">
          Doğrulama e-postasını gönder.
        </h1>
        <p>Sayın <b>{{ userObj.firstName }} {{ userObj.lastName }}</b>, <br> e-posta adresiniz doğrulanamadı.
          <br>Doğrulama e-postasını tekrar alabilmek için tıklayınız.</p><br>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="onClicked()"
        >
          <feather-icon
            icon="MailIcon"
            class="mr-50"
          />
          <span class="align-middle">Gönder</span>
        </b-button>
      </div>
      <div
        v-if="userObj.rowState===1"
      >
        <h1 class="success">
          Doğrulama başarılı.
        </h1>
        <p>Sayın <b>{{ userObj.firstName }} {{ userObj.lastName }}</b>, <br> e-posta adresiniz doğrulandı.
          <br>Giriş yapmak için tıklayınız.</p><br>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="onHome()"
        >
          <feather-icon
            icon="HomeIcon"
            class="mr-50"
          />
          <span class="align-middle">Giriş</span>
        </b-button>
      </div>
    </center>
  </div>
</template>

<script>
import * as apiAuth from '@/api/auth'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton, BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      qs: this.$route.query.value,
      userObj: { rowState: 0 },
    }
  },
  created() {
    this.getQuery()
  },
  methods: {
    getQuery() {
      apiAuth.VerifyEMailAddress(this.qs).then(res => {
        this.userObj = res.data.entity
        if (res.data.entity === null) this.userObj = { rowState: 0 }
        if (this.userObj.rowState === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-Posta Doğrulama',
              text: 'E-Posta adresiniz doğrulanmıştır. Giriş yapabilirsiniz.',
              icon: 'CheckIcon',
              variant: 'success',
              type: 'success',
            },
          })
        }
        if (this.userObj.rowState === 2) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-Posta Doğrulanamadı',
              text: 'E-Posta adresiniz doğrulanamadı.',
              icon: 'XIcon',
              variant: 'danger',
              type: 'error',
            },
          })
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'E-Posta Doğrulanamadı',
            text: err.message,
            icon: 'XIcon',
            type: 'error',
            variant: 'danger',
          },
        })
      })
    },
    onClicked() {
      apiAuth.SendActivationEMail(this.userObj.eMail).then(res => {
        if (res.data.entity) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-Posta Gönderildi',
              text: 'Doğrulama e-postası adresinize gönderildi.',
              icon: 'MailIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    onHome() {
      this.$router.push('home')
    },
  },
}
</script>
